import React from 'react';
import './TestingPotential.css';

const TestingPotential = () => {
  return (
    <section className="testing-potential">
      <div className="testing-potential-content">
        <h2>Unleash Your Testing <span className="highlight">Potential</span></h2>
        <p>
          Leverage the power of AI and intelligent automation to streamline your testing processes. 
          With QTM, create, execute, and analyze tests faster than ever, eliminating repetitive tasks 
          and reducing human error. Our platform allows you to focus on high-impact areas while the 
          AI handles the heavy lifting, ensuring your software meets the highest standards of quality— 
          efficiently and effortlessly.
        </p>
        <div className="testing-features-grid">
          <div className="testing-feature">
            <div className="icon">🛠️</div>
            <h3>Powerful Features</h3>
            <p>From test plan creation to result reporting, QTM provides a comprehensive suite of tools to enhance your testing capabilities.</p>
          </div>
          <div className="testing-feature">
            <div className="icon">🔍</div>
            <h3>Streamlined Workflow</h3>
            <p>Integrate QTM with your existing tools and processes for a more efficient testing experience.</p>
          </div>
          <div className="testing-feature">
            <div className="icon">📊</div>
            <h3>Unparalleled Insights</h3>
            <p>Gain deep visibility into your testing results, with comprehensive reporting and analytics to drive informed decision-making.</p>
          </div>
          <div className="testing-feature">
            <div className="icon">🤝</div>
            <h3>Collaborative Approach</h3>
            <p>Empower your team to work together, sharing test cases, assigning tasks, and aligning on testing goals.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestingPotential;
