import React, { useState, useEffect } from "react";
import "./NavigationBar.css";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";

const NavigationBar = () => {
  const [showIcon, setshowIcon] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const onHamburgerClick = () => {
    setMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      // Check window width and update showIcon state accordingly
      setshowIcon(window.innerWidth <= 1280); // Adjust the width threshold as needed
    };

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div id="Headerbar">
      <div>
        <div className="navBar">
          {showIcon && (
            <div onClick={onHamburgerClick}>
              <GiHamburgerMenu size={32} className="hamburgerIcon" />
            </div>
          )}
          <div>
            <Link to="/">
              <img
                src="./transparentcentered.png"
                alt="logo-of-company"
                className="logoImage"
              />
            </Link>
          </div>
          <ul className="desktop-nav-links">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/products">Products</Link>
            </li>
            <li>
              <Link to="/careers">Careers</Link>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li className="contact-button">
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
      {isMenuOpen && (
        <div>
          <ul className="mobileMenu">
            <li>
              <Link to="/" className="mobileLink">
                Home
              </Link>
            </li>
            <li>
              <Link to="/services" className="mobileLink">
                Services
              </Link>
            </li>
            <li>
              <Link to="/products" className="mobileLink">
                Products
              </Link>
            </li>
            <li>
              <Link to="/careers" className="mobileLink">
                Careers
              </Link>
            </li>
            <li>
              <Link to="/about-us" className="mobileLink">
                About Us
              </Link>
            </li>
            <li>
              <Link to="/contact-us" className="mobileLink">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default NavigationBar;
