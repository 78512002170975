import React from 'react';
import { useNavigate } from "react-router-dom";
import './TestingWorkflow.css';  // Ensure the CSS file name matches

const TestingWorkflow = () => {
  const navigate = useNavigate();

  const navigateToContactUs = () => {
    navigate('/contact-us');
  };

  return (
    <section className="testing-workflow">
      <div className="workflow-text">
        <h2>Streamline Your Testing <span className="highlight">Workflow</span></h2>
        <p>
          With QTM, your team can effortlessly manage the entire testing life-cycle, 
          from test plan creation to result reporting.
        </p>
        <button onClick={navigateToContactUs} className="learn-more-btn">Learn More</button>
        {/*<p className="sub-text">Discover the Power of QTM</p>*/}
      </div>
      <div className="workflow-image">
        <img src="./testing_workflow.png" alt="Illustration of a laptop with CSS, HTML, and Java tags, symbolizing QTM's comprehensive testing workflow management." />
      </div>
    </section>
  );
};

export default TestingWorkflow;