import React from 'react';
import './StreamlinedTesting.css';
import { useNavigate } from "react-router-dom";

const StreamlinedTesting = () => {
  const navigate = useNavigate();

  const navigateToContactUs = () => {
    navigate('/contact-us');
  };

  return (
    <section className="streamlined-testing">
      <h2>Streamlined Testing with QTM</h2>
      <p>
        Discover how QTM can transform your software testing process. Leverage its powerful features, 
        seamless integration, and data-driven insights to deliver high-quality software with confidence.
      </p>
      <button onClick={navigateToContactUs} className="learn-more-button">Learn More</button>
    </section>
  );
};

export default StreamlinedTesting;