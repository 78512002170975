import React, { useEffect } from "react";
import FooterBar from "../common/FooterBar";
import NavigationBar from "../common/NavigationBar";
import ReactGA from "react-ga";

import MainSection from '../qtm/MainSection';
import TestingPotential from '../qtm/TestingPotential';
import StreamlinedTesting from '../qtm/StreamlinedTesting';
import PoweringQuality from '../qtm/PoweringQuality';
import TestingWorkflow from '../qtm/TestingWorkflow';
import MaximizeEfficiency from '../qtm/MaximizeEfficiency';

const Products = () => {
  useEffect(() => {
    // Track the page view on component mount
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div>
      <NavigationBar />
      <MainSection />
      <TestingPotential />
      <StreamlinedTesting />
      <PoweringQuality />
      <TestingWorkflow />
      <MaximizeEfficiency />
      <FooterBar />
    </div>
  );
};

export default Products;
