import React from 'react';
import './MaximizeEfficiency.css';

const MaximizeEfficiency = () => {
  return (
    <section className="maximize-efficiency">
      <h2>Maximize Your Testing <span className="highlight">Efficiency</span></h2>
      <div className="efficiency-grid">
        <div className="efficiency-card">
          <h3>Explore QTM</h3>
          <p>From seamless Jira and Git integrations to advanced reporting capabilities, QTM provides the tools you need to elevate your testing game and deliver exceptional software.</p>
        </div>
        <div className="efficiency-card">
          <h3>Get Started</h3>
          <p>Discover how QTM can streamline your testing workflows, empower your team, and drive data-driven decision-making.</p>
        </div>
      </div>
    </section>
  );
};

export default MaximizeEfficiency;