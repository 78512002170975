import "./Card.css";
import React from "react";
const Card = ({ title, description }) => {
  return (
    <div className="Card">
      <div>
        <img src="./tiny-logo.png" className="tiny-logo"></img>
      </div>
      <p className="cardTitle">{title}</p>
      <p className="cardDescription">{description}</p>
    </div>
  );
};

export default Card;
