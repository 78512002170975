import "./PurpleBox.css";

const PurpleBox = () => {
  return (
    <div className="purpleBox">
      <div className="purpleBoxLeftWrapper">
        <h2>Have a project in mind?</h2>
        <p className="purpleBoxText">
          Start the collaboration with us while figuring out the best solution
          based on your needs.
        </p>
      </div>

      <a href="/contact-us" className="contact-us-button">
        Contact Us
      </a>
    </div>
  );
};

export default PurpleBox;
