import React from 'react';
import { useNavigate } from "react-router-dom";
import './PoweringQuality.css';

const PoweringQuality = () => {
  const navigate = useNavigate();

  const navigateToContactUs = () => {
    navigate('/contact-us');
  };

  return (
    <section className="powering-quality">
      <div className="quality-text">
        <h2>Powering <span className="highlight">Quality</span> with QTM</h2>
        <p>
          At the heart of QTM lies a powerful integration that seamlessly connects your testing efforts 
          with your development processes. Leverage the tool’s Jira and Git integrations to maintain 
          visibility and traceability across your entire software lifecycle.
        </p>
        <button onClick={navigateToContactUs} className="try-btn">Try QTM</button>
        {/* <p className="sub-text">Experience the Future of Software Testing</p> */}
      </div>
      <div className="quality-image">
        <img src="./powering_quality.png" alt="Illustration of team members interacting with a digital interface, showcasing QTM's seamless integration and testing capabilities." />
      </div>
    </section>
  );
};

export default PoweringQuality;