import React from 'react';
import './Features.css';

const Features = () => {
  return (
    <div className="features">
      <div className="feature">
        <div className="feature-check">✔</div>
        <h3>Seamless Integration</h3>
        <p>Effortlessly integrate with Jira. Sync your projects, issues, and test cases automatically, creating a unified testing workflow across platforms.</p>
      </div>
      <div className="feature">
        <div className="feature-check">✔</div>
        <h3>Zero-Code Automation</h3>
        <p>Test your applications without writing a single line of code. QTM's intuitive interface allows anyone on your team to build, execute, and maintain tests.</p>
      </div>
      <div className="feature">
        <div className="feature-check">✔</div>
        <h3>
        Visual Testing with AI</h3>
        <p>Harness AI-driven visual detection to automatically identify design flaws and UI inconsistencies. </p>
      </div>
      <div className="feature">
        <div className="feature-check">✔</div>
        <h3>Integrity and Confidentiality</h3>
        <p>We operate in accordance with utmost moral and professional standards.</p>
      </div>
    </div>
  );
};

export default Features;