import "./ServiceBody.css";
import PurpleBox from "./PurpleBox";
import { Helmet } from "react-helmet";

const ServicesBody = () => {

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Services - Quality Aces",
    "description": "Explore the wide range of software testing services offered by Qualaces, including smoke testing, regression testing, hardware testing, and more.",
    "url": "https://www.qualaces.com/services",
    "publisher": {
      "@type": "Organization",
      "name": "Qualaces",
      "url": "https://www.qualaces.com",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.qualaces.com/logo.png",
        "width": 200,
        "height": 50
      },
      "sameAs": [
        "https://www.linkedin.com/company/qualaces",
        "https://twitter.com/qualaces"
      ]
    },
    "mainEntity": {
      "@type": "Service",
      "name": "Software Testing Services",
      "description": "Qualaces provides a wide range of software testing services to ensure the quality, performance, and security of your software products.",
      "provider": {
        "@type": "Organization",
        "name": "Qualaces",
        "url": "https://www.qualaces.com"
      },
      "serviceType": [
        {
          "@type": "Service",
          "name": "Smoke Testing",
          "description": "Ensures that the most critical functionalities of a system are working fine.",
          "image": "https://www.qualaces.com/images/card_image_1.png"
        },
        {
          "@type": "Service",
          "name": "Sanity Testing",
          "description": "Verifies that minor changes in the code or functionality have fixed the bugs.",
          "image": "https://www.qualaces.com/images/card_image_2.png"
        },
        {
          "@type": "Service",
          "name": "Regression Testing",
          "description": "Conducts exhaustive testing when changes or enhancements are made to the software.",
          "image": "https://www.qualaces.com/images/card_image_3.png"
        },
        {
          "@type": "Service",
          "name": "Ticket Testing",
          "description": "Tests individual items or 'tickets' that are part of an issue tracking system.",
          "image": "https://www.qualaces.com/images/card_image_4.png"
        },
        {
          "@type": "Service",
          "name": "Hardware Testing",
          "description": "Verifies the product's full integration and evaluates end-to-end system standards.",
          "image": "https://www.qualaces.com/images/Services_Image_3.jpg"
        },
        {
          "@type": "Service",
          "name": "Automation Testing",
          "description": "Uses automated methods to test software components with minimal developer participation.",
          "image": "https://www.qualaces.com/images/Services_Image_7.jpg"
        },
        {
          "@type": "Service",
          "name": "IoT Testing",
          "description": "Examines the performance, security, and functionality of IoT devices.",
          "image": "https://www.qualaces.com/images/Services_Image_2.webp"
        },
        {
          "@type": "Service",
          "name": "Artificial Intelligence Testing",
          "description": "Uses AI and machine learning to improve automated software testing outcomes.",
          "image": "https://www.qualaces.com/images/Services_Image_9.jpeg"
        },
        {
          "@type": "Service",
          "name": "Virtual Reality Testing",
          "description": "Combines augmented reality with software testing to enhance the testing process.",
          "image": "https://www.qualaces.com/images/Services_Image_4.jpg"
        },
        {
          "@type": "Service",
          "name": "Mobile Testing",
          "description": "Evaluates the functionality, usability, and consistency of mobile apps and digital experiences.",
          "image": "https://www.qualaces.com/images/Services_Image_6.jpg"
        },
        {
          "@type": "Service",
          "name": "Chatbot Testing",
          "description": "Tests AI-powered chatbots to ensure they can engage users effectively in natural language.",
          "image": "https://www.qualaces.com/images/Services_Image_10.jpg"
        },
        {
          "@type": "Service",
          "name": "Website Testing",
          "description": "Ensures websites function correctly and provide a seamless user experience.",
          "image": "https://www.qualaces.com/images/Services_Image_8.jpeg"
        }
      ]
    }
  };

  
  return (
    <div className="ServicesBodyWrapper">
      {/* Injected schema markup */}
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>

      <div className="ServicesBodySection1">
        <p className="discover-text centerAlignedText">WHAT WE DO</p>
        <div>
          <p className="aboutHeading">Our Software</p>
          <p className="aboutHeading">
            Testing <span className="qualityAces">Services</span>
          </p>
        </div>
        <div className="ServicesBodySection1Body">
          <p className="centerAlignedText">
            Join forces with Qualaces today to achieve a higher standard of
            software quality! In order to guarantee that your software products
            operate seamlessly and uphold the highest standards of quality and
            dependability, our firm provides a wide range of services.
          </p>
        </div>
        <h2>So lets explore!</h2>
      </div>
      <div className="ServicesBodySection2">
        <div>
          <p className="discover-text">WHAT WE PROVIDE</p>
          <p className="aboutHeading">The Main Types of Testing</p>
        </div>
        <div className="squareCardWrapper">
          <div className="servicesSquareCard">
            <img src="./card_image_1.png"  alt="Clipboard icon with checkmarks representing smoke testing process" className="servicesCardImage" />
            <p>Smoke Testing</p>
          </div>
          <div className="servicesSquareCard">
            <img src="./card_image_2.png" alt="Circular arrows icon showing cyclical process of sanity testing" className="servicesCardImage" />
            <p>Sanity Testing</p>
          </div>
          <div className="servicesSquareCard">
            <img src="./card_image_3.png" alt="Command line window icon representing regression testing interface" className="servicesCardImage" />
            <p>Regression Testing</p>
          </div>
          <div className="servicesSquareCard">
            <img src="./card_image_4.png" alt="Puzzle piece icon with one purple piece representing ticket testing workflow" className="servicesCardImage" />
            <p>Ticket Testing</p>
          </div>
        </div>
      </div>
      <div className="ServicesBodySection3">
        <div className="ServicesBodySection">
          <div className="ServicesBodySectionWrapper">
            <div className="ServicesLeftContainer">
              <h2 className="discover-text">TYPE</h2>
              <p className="aboutHeading">Hardware Testing</p>
              <div className="aboutUsTextContainer">
                <p className="lineheight">
                  Hardware testing verifies the product's full integration. It
                  is one of the last stages of the product development process.
                  The evaluation of end-to-end system standards and the
                  provision of knowledge regarding a product's quality are the
                  goals of the test.
                </p>
              </div>
            </div>
            <div className="ServicesImageContainer">
              <img src="./Services_Image_3.jpg" alt="Close-up of a computer motherboard with CPU socket and gold circuit traces, illustrating hardware testing components" className="aboutUsImage" />
            </div>
          </div>
        </div>
        <div className="ServicesBodySection purplebackground">
          <div className="ServicesBodySectionWrapper">
            <div className="ServicesImageContainer">
              <img src="./Services_Image_7.jpg" alt="Professional workstation setup with multiple monitors displaying testing interfaces, featuring a modern control room environment with ambient lighting and curved display screens" className="aboutUsImage" />
            </div>
            <div className="ServicesLeftContainer">
              <h2 className="discover-text">TYPE</h2>
              <p className="aboutHeading">Automation Testing</p>
              <div className="aboutUsTextContainer">
                <p className="lineheight">
                  Automation testing refers to a method or procedure where the
                  computer does several tests on different software components
                  with little to no developer participation. Before it was made
                  available to users, any software that we use on a daily basis,
                  including that which runs your phone, laptop, TV, or even your
                  car, had to pass a predetermined process.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="ServicesBodySection">
          <div className="ServicesBodySectionWrapper">
            <div className="ServicesLeftContainer">
              <h2 className="discover-text">TYPE</h2>
              <p className="aboutHeading">IOT Testing </p>
              <div className="aboutUsTextContainer">
                <p className="lineheight">
                  IoT testing comprises doing QA tests to examine the
                  performance, security, and functionality of IoT devices. Every
                  IoT device transmits and receives data over the Internet, so
                  it is essential to confirm that they can transfer critical
                  information wirelessly before releasing them.
                </p>
              </div>
            </div>
            <div className="ServicesImageContainer">
              <img src="./Services_Image_2.webp" alt="Smart city visualization with network connectivity icons and mesh overlay on blue-tinted cityscape, illustrating IoT device interconnections and wireless communication networks" className="aboutUsImage" />
            </div>
          </div>
        </div>
        <div className="ServicesBodySection bluebackground">
          <div className="ServicesBodySectionWrapper">
            <div className="ServicesImageContainer">
              <img src="./Services_Image_9.jpeg" alt="Person wearing VR headset in dramatic blue lighting, representing artificial intelligence testing technology and virtual reality integration" className="aboutUsImage" />
            </div>
            <div className="ServicesLeftContainer">
              <h2 className="discover-text">TYPE</h2>
              <p className="aboutHeading">Artificial Intelligence Testing</p>
              <div className="aboutUsTextContainer">
                <p className="lineheight">
                  The use of automated software testing methods that make use of
                  artificial intelligence (AI)—typically, machine learning—to
                  get better outcomes is known as artificial intelligence
                  testing. The notion is that those technologies can get past
                  many of the usual obstacles in automated software testing with
                  the use of artificial intelligence.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="ServicesBodySection">
          <div className="ServicesBodySectionWrapper">
            <div className="ServicesLeftContainer">
              <h2 className="discover-text">TYPE</h2>
              <p className="aboutHeading">Virtual Reality Testing</p>
              <div className="aboutUsTextContainer">
                <p className="lineheight">
                  By fusing the virtual and actual worlds, augmented reality
                  (AR) and virtual reality (VR) applications offer a new level
                  of immersion. Augmented reality-based testing (ARBT) combines
                  augmented reality with software testing in order to enhance
                  testing by introducing a new dimension to the testers' field
                  of vision.
                </p>
              </div>
            </div>
            <div className="ServicesImageContainer">
              <img src="./Services_Image_4.jpg" alt="Person wearing white VR headset and plaid shirt, gesturing with hands while experiencing virtual reality in an indoor setting with warm lighting" className="aboutUsImage" />
            </div>
          </div>
        </div>
        <div className="ServicesBodySection purplebackground">
          <div className="ServicesBodySectionWrapper">
            <div className="ServicesImageContainer">
              <img src="./Services_Image_6.jpg" alt="Laptop keyboard in dark setting with green code visible on screen, representing mobile testing and development environment" className="aboutUsImage" />
            </div>
            <div className="ServicesLeftContainer">
              <h2 className="discover-text">TYPE</h2>
              <p className="aboutHeading"> Mobile Testing </p>
              <div className="aboutUsTextContainer">
                <p className="lineheight">
                  Mobile testing is a procedure for evaluating the relevance,
                  functionality, usability, and consistency of mobile apps and
                  digital experiences (such as websites and e-commerce). Mobile
                  testing will guarantee that all of your goods and experiences
                  yield the best results, whether you're creating a responsive
                  website, redesigning an existing one, or creating the next
                  great app.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="ServicesBodySection">
          <div className="ServicesBodySectionWrapper">
            <div className="ServicesLeftContainer">
              <h2 className="discover-text">TYPE</h2>
              <p className="aboutHeading">Chatbot Testing</p>
              <div className="aboutUsTextContainer">
                <p className="lineheight">
                  A chatbot is an artificial intelligence (AI) computer that can
                  start and continue a conversation with a user via messaging in
                  natural language. For instance, users can easily get what they
                  want by using the chatbot on Domino's website. Users have a
                  range of alternatives to pick from, as well as the chance to
                  engage with people.
                </p>
              </div>
            </div>
            <div className="ServicesImageContainer">
              <img src="./Services_Image_10.jpg" alt="Digital illustration of a hand holding a glowing chatbot icon with floating communication bubbles and tech symbols like wifi and mobile icons on a dark blue background" className="aboutUsImage" />
            </div>
          </div>
        </div>
        <div className="ServicesBodySection purplebackground">
          <div className="ServicesBodySectionWrapper">
            <div className="ServicesImageContainer">
              <img src="./Services_Image_8.jpeg" alt="MacBook Pro laptop displaying code on dark screen with development tools in the dock, representing website testing environment" className="aboutUsImage" />
            </div>
            <div className="ServicesLeftContainer">
              <h2 className="discover-text">TYPE</h2>
              <p className="aboutHeading">Website Testing</p>
              <div className="aboutUsTextContainer">
                <p className="lineheight">
                  A chatbot is an artificial intelligence (AI) computer that can
                  start and continue a conversation with a user via messaging in
                  natural language. For instance, users can easily get what they
                  want by using the chatbot on Domino's website. Users have a
                  range of alternatives to pick from, as well as the chance to
                  engage with people.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="purpleBoxContainer">
        <PurpleBox />
      </div>
    </div>
  );
};

export default ServicesBody;
