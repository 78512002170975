import "./AboutUsSection.css";
import PurpleBox from "./PurpleBox.js";

const AboutUsSection = () => {
  return (
    <div className="aboutUsBody">
      <div className="aboutUsTopContainer">
        <p className="aboutUsBodyHeader">About Us</p>
        <div className="aboutUsTopContainerTextWrapper">
          <p className="aboutUsHeaderText">
            Welcome to Qualaces! We are a Quality Aces who are dedicated to
            delivering products and services with the highest standards and
            dependability.
          </p>
        </div>
      </div>
      <div className="squareCardContainer">
        <div className="squareCard">
          <img src="./card_image_1.png" alt= "Clipboard icon with checkmarks representing smoke testing process." className="squareCardImage" />
          <p>
            <strong>Expertise</strong>
          </p>
          <p>
            Our team is made up of professional quality assurance specialists
            with broad expertise.
          </p>
        </div>
        <div className="squareCard">
          <img src="./card_image_2.png" alt = "Circular arrows icon showing cyclical process of sanity testing. " className="squareCardImage" />
          <p>
            <strong>Personalized Approach</strong>
          </p>
          <p>We give our clients' needs and objectives top priority.</p>
        </div>
        <div className="squareCard">
          <img src="./card_image_3.png" alt = "Command line window icon representing regression testing interface." className="squareCardImage" />
          <p>
            <strong>Customized Solutions</strong>
          </p>
          <p>
            Our solutions are customized to fit each client's unique objectives,
            financial constraints, and time constraints.
          </p>
        </div>
        <div className="squareCard">
          <img src="./card_image_4.png" alt = "Puzzle piece icon with one purple piece representing ticket testing workflow." className="squareCardImage" />
          <p>
            <strong>Continues Improvement</strong>
          </p>
          <p>
            We are dedicated to staying in the lead in the constantly changing
            quality assurance environment.
          </p>
        </div>
        <div className="squareCard">
          <img src="./card_image_1.png" alt = "Clipboard icon with checkmarks representing smoke testing process. " className="squareCardImage" />
          <p>
            <strong>Integrity and Confidentiality</strong>
          </p>
          <p>
            We do our business in accordance with the strictest codes of ethics
            and professionalism.
          </p>
        </div>
      </div>
      <div className="AboutUsBodySection">
        <div className="AboutUsLeftContainer">
          <div>
            <h2 className="discover-text">ABOUT US</h2>
            <p className="aboutHeading">Who are we?</p>
          </div>
          <div className="aboutUsTextContainer">
            <p className="lineheightsmall">
              At our foundation, we think that success is driven by a dedication
              to excellence. Our goal is to assist companies of all sizes to
              meet their greatest expectations of quality. We make a conscious
              effort to offer thorough quality assurance solutions that are
              suited to each client's particular demands.
            </p>
            <p className="lineheightsmall">
              We seek to increase customer satisfaction and promote long-term
              success for our clients by utilizing our understanding of the
              industry, expertise, and cutting-edge tools. We are experts at
              offering comprehensive quality assurance services to a range of
              sectors.
            </p>
            <p className="lineheightsmall">
              Our devoted team collaborates directly with clients to comprehend
              their unique needs, difficulties, and objectives.
            </p>
          </div>
        </div>
        <div className="ServicesImageContainer">
          <img src="./aboutUsImage.png" alt = "A diverse team of professionals collaborating around a laptop in a modern office space discussing business strategy during a casual meeting." className="aboutUsImage" />
        </div>
      </div>
      <div className="AboutUsBodySection displayOffDesktop">
        <div className="AboutUsLeftContainer">
          <div>
            <h2 className="discover-text">FAST & EASY</h2>
            <p className="aboutHeading">We can help!</p>
          </div>
          <div className="aboutUsTextContainer">
            <p className="lineheight">
              Come along for the ride as we pursue quality, and see the
              difference we can make for your company. Together, we can raise
              the bar for excellence and establish new benchmarks. To find out
              more about our offerings and how we can help you produce great
              quality in your goods and services, get in touch with us right
              away.
            </p>
          </div>
        </div>
        <div className="ServicesImageContainer">
          <img src="./aboutUsImage.png" alt = "A diverse team of professionals collaborating around a laptop in a modern office space, discussing business strategy during a casual meeting." className="aboutUsImage" />
        </div>
      </div>
      <div className="AboutUsBodySection displayOffMobile">
        <div className="ServicesImageContainer">
          <img src="./aboutUsImage.png" className="aboutUsImage" />
        </div>
        <div className="AboutUsLeftContainer">
          <div>
            <h2 className="discover-text">FAST & EASY</h2>
            <p className="aboutHeading">We can help!</p>
          </div>
          <div className="aboutUsTextContainer">
            <p className="lineheight">
              Come along for the ride as we pursue quality, and see the
              difference we can make for your company. Together, we can raise
              the bar for excellence and establish new benchmarks. To find out
              more about our offerings and how we can help you produce great
              quality in your goods and services, get in touch with us right
              away.
            </p>
          </div>
        </div>
      </div>
      <PurpleBox />
    </div>
  );
};

export default AboutUsSection;
