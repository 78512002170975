import React from "react";
import { useEffect } from "react";
import "./FooterBar.css";
import { Link } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";

const FooterBar = () => {
  useEffect(() => {
    smoothscroll.polyfill();
    document.querySelectorAll(".scroll-to-top").forEach(function (link) {
      link.addEventListener("click", function () {
        window.scrollTo({
          top: 0,
          behavior: "auto",
        });
      });
    });
  });
  return (
    <div className="copyrightDiv">
      <footer className="Doggera-footer">
        <ul className="doggora-footer-list1">
          <li className="footerlist scroll-to-top">
            <Link to="/careers" className="footerlink">
              Career
            </Link>
          </li>
          <li className="footerlist scroll-to-top">
            <Link to="/about-us" className="footerlink">
              About Us
            </Link>
          </li>
          <li className="footerlist scroll-to-top">
            <Link to="/contact-us" className="footerlink">
              Contact Us
            </Link>
          </li>
          <li className="footerlist scroll-to-top">
            <Link to="/services" className="footerlink">
              Services
            </Link>
          </li>
          <li className="footerlist scroll-to-top">
            <Link to="/privacy-policy" className="footerlink">
              Privacy Policy
            </Link>
          </li>
        </ul>
        <ul className="doggora-footer-list3">
          <li className="footerlist">
            <a
              href="https://www.linkedin.com/company/qualaces/"
              className="footerlink"
              target="_blank"
              rel="noreferrer"
            >
              <img src="./LinkedIn.png" alt="LinkedIn" className="icon-image" />
              LinkedIn
            </a>
          </li>
          <li className="footerlist">
            <a
              href="https://www.twitter.com/qualaces"
              className="footerlink"
              target="_blank"
              rel="noreferrer"
            >
              <img src="./twitter.png" alt="twitter" className="icon-image" />
              Twitter / X
            </a>
          </li>
          <li className="footerlist">
            <a
              href="https://www.facebook.com/qualaces"
              className="footerlink"
              target="_blank"
              rel="noreferrer"
            >
              <img src="./facebook.png" alt="facebook" className="icon-image" />
              Facebook
            </a>
          </li>
          <li className="footerlist">
            <a
              href="https://www.instagram.com/qualaces"
              className="footerlink"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="./Instagram.png"
                alt="Instagram"
                className="icon-image"
              />
              Instagram
            </a>
          </li>
          <li className="footerlist">
            <a
              href="https://www.youtube.com/@qualaces"
              className="footerlink"
              target="_blank"
              rel="noreferrer"
            >
              <img src="./youtube.png" alt="youtube" className="icon-image" />
              YouTube
            </a>
          </li>
        </ul>
        <div className="footer-company-info">
          <img
            src="./betterqualitylogo.png"
            alt="logo-of-company"
            className="footerImage"
          />
          <div>
            <p>
              Qualaces is a software and quality testing services company that
              provides a wide range of services to ensure the success of your
              software.
            </p>
          </div>
        </div>
        <div className="footer-company-info">
          <p className="bigFonts">Our Contact:</p>
          <p>Email: info@qualaces.com</p>
          <p>Telephone: +1 (587) 319-6658</p>
          <p>Located at: Calgary, Alberta</p>
          <p>Canada</p>
        </div>
      </footer>
      <p>© 2021-2023 Qualaces. All Rights Reserved.</p>
    </div>
  );
};

export default FooterBar;
