import React from 'react';
import { useNavigate } from 'react-router-dom';
import './MainSection.css';
import Features from './Features'; // Assuming this is the component with the boxes

  /*import MainSectionImage from '../assets/MainSection.png'; // Update with the correct path to your image*/

const MainSection = () => {
  const navigate = useNavigate();

  const navigateToContactUs = () => {
    navigate('/contact-us');
  };

  return (
    <section className="main-section">
      <div className="content">
        <div className="text-content">
          <h1 className="main-heading">
            QTM: <span className="highlight">Powerful</span> Software Testing Tool
          </h1>
          <p className="description">
            Empower your team to create and run tests without writing a single line of code, using the power of ChatGPT and AI. 
            Seamlessly integrate with your development workflow and let AI handle the heavy lifting.
          </p>
          <div className="button-container">
            <button onClick={navigateToContactUs}>Sign up for QTM!</button>
          </div>
        </div>

        <div className="features">
          <Features/>
        </div>
        {/*<div className="image-content">
          <img src={MainSectionImage} alt="Main Section Illustration" />
        </div>*/}
      </div>
    </section>
  );
};

export default MainSection;
