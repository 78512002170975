import "./FindOutMoreButton.css";

const FindOutMoreButton = () => {
  return (
    <div>
      <a href="/services" className="findMoreButton">
        Find out more
      </a>
    </div>
  );
};

export default FindOutMoreButton;
