import React, { useEffect } from "react";
import FooterBar from "../common/FooterBar";
import NavigationBar from "../common/NavigationBar";
import ReactGA from "react-ga";
import PrivacyPolicy from "../common/PrivacyPolicy";

const PrivacyPolicyPage = () => {
  useEffect(() => {
    // Track the page view on component mount
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div>
      <NavigationBar />
      <PrivacyPolicy />
      <FooterBar />
    </div>
  );
};

export default PrivacyPolicyPage;
