import "./CareersBody.css";
import React from "react";

import { Helmet } from "react-helmet";

const CareersBody = () => {

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Careers - Qualaces",
    "description": "Explore career opportunities and the mentorship program at Qualaces. Learn how we can help you build your career in quality assurance.",
    "url": "https://www.qualaces.com/careers",
    "publisher": {
      "@type": "Organization",
      "name": "Qualaces",
      "url": "https://www.qualaces.com",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.qualaces.com/logo.png",
        "width": 200,
        "height": 50
      },
      "sameAs": [
        "https://www.linkedin.com/company/qualaces",
        "https://twitter.com/qualaces"
      ]
    },
    "mainEntity": {
      "@type": "EducationalOccupationalProgram",
      "name": "Qualaces Quality Assurance Mentorship Program",
      "description": "Unlock your full potential, accelerate your career, and become a proficient QA professional with the Qualaces Quality Assurance Mentorship Program.",
      "provider": {
        "@type": "Organization",
        "name": "Qualaces",
        "url": "https://www.qualaces.com"
      },
      "educationalProgramMode": "Online",
      "occupationalCategory": "Quality Assurance",
      "hasCourse": [
        {
          "@type": "Course",
          "name": "Career Building Guidance",
          "description": "Personalized career advice and guidance from experienced mentors at Qualaces."
        },
        {
          "@type": "Course",
          "name": "Valuable Certifications",
          "description": "Acquire industry-recognized certifications that add significant value to your professional profile."
        },
        {
          "@type": "Course",
          "name": "Hands-On Experience",
          "description": "Immerse yourself in real-world projects and scenarios, gaining practical experience under the guidance of seasoned professionals."
        },
        {
          "@type": "Course",
          "name": "Latest Tools and Technology",
          "description": "Learn to navigate and master cutting-edge QA tools, ensuring you are equipped with the skills demanded by the industry."
        },
        {
          "@type": "Course",
          "name": "Fast Track Your Career",
          "description": "Receive guidance on making strategic career moves and advancing quickly within the QA domain."
        },
        {
          "@type": "Course",
          "name": "One-on-One Mentoring",
          "description": "Enjoy one-on-one mentorship sessions tailored to your specific career goals and learning needs."
        }
      ]
    }
  };


  return (
    <div className="CareersBodyWrapper">
      {/* Injected schema markup */}
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>

      <div className="careerPageSection1">
        <p className="careerPageSection1Header">Careers</p>
        <div className="careerPageSection1TextWrapper">
          <p>
            As technology and industries evolve, staying up-to-date with the
            latest QA methodologies and tools is crucial to provide the best
            possible service.
          </p>
        </div>
      </div>
      <div className="careerPageSection2">
        <div className="careersPage1Wrapper">
          <img
            src={process.env.PUBLIC_URL + "/careerHeroImage.png"}
            // src="./careerHeroImage.png"
            className="careersPage1"
            alt="Line illustration of three people in a casual office setting.One person is seated at a desk with a computer monitor, another is standing behind looking at the screen, and a third person is standing to the right holding a mobile phone. The illustration uses purple and white colors with simple black outlines."
          />
        </div>
        <div className="careerHelpContainerHeadingWrapper">
          <p className="careerHelpContainerHeading">
            <span className="discover-text">Sorry.</span> We have no openings
            currently.
          </p>
          <p>
            However, feel free to submit your resume to careers@qualaces.com,
            and we will reach out to you when suitable opportunities arise.
            Thank you for your interest!
          </p>
        </div>
      </div>
      <div className="careerPageSection3">
        <img
          src="./CareersPage4.JPG"
          className="CareersPageImage4"
          alt="Professional working at desk with laptop in modern office setting, alongside text describing Qualaces Quality Assurance Mentorship Program on purple background"
        ></img>
        <div className="careerPageSection3rightCotainer">
          <p className="careerHelpHead">Our Mentorship Program</p>
          <div className="careerPageSection3Text">
            <p>
              Join the Qualaces Quality Assurance Mentorship Program to unlock
              your full potential, accelerate your career, and become a
              proficient QA professional. Whether you are a recent graduate or
              looking to transition into the QA field, our program provides a
              solid foundation for success in a rapidly evolving tech landscape.
            </p>
            <p>
              Don't just follow the path; forge your own with Qualaces. Elevate
              your skills, amplify your impact, and shape the future of quality
              assurance.
            </p>
          </div>
        </div>
      </div>
      <div className="careerPageSection4">
        <div className="careerHelpContainerHeading">
          How We Can <span className="discover-text">Help</span>
        </div>
        <div className="careerHelpContainerWrapper">
          <div className="careerHelpContainer">
            <div>
              <p className="careerHelpHead">Career Building Guidance</p>
              <p>
                Benefit from personalized career advice and guidance from
                experienced mentors at Qualaces.
              </p>
            </div>
            <div>
              <p className="careerHelpHead">Valuable Certifications</p>
              <p>
                Acquire industry-recognized certifications that add significant
                value to your professional profile.
              </p>
            </div>
            <div>
              <p className="careerHelpHead">Hands On Experiance</p>
              <p>
                Immerse yourself in real-world projects and scenarios, gaining
                practical experience under the guidance of seasoned
                professionals.
              </p>
            </div>
          </div>
          <div className="careerHelpImageWrapper">
            <img
              src="./CareersPage3.png"
              className="careerImage2"
              alt="Infographic showing 'How We Can Help' services with six key offerings: Career Building Guidance, Valuable Certifications, Hands-On Experience, Latest Tools and Technology, Fast Track Your Career, and One-on-One mentoring. Features a purple and white illustrated figure with a clipboard in the center."
            />
          </div>
          <div className="careerHelpContainer">
            <div>
              <p className="careerHelpHead">Latest Tools and Technology</p>
              <p>
                Learn to navigate and master cutting-edge QA tools, ensuring you
                are equipped with the skills demanded by the industry.
              </p>
            </div>
            <div>
              <p className="careerHelpHead">Fast Track Your Career</p>
              <p>
                Receive guidance on making strategic career moves and advancing
                quickly within the QA domain.
              </p>
            </div>
            <div>
              <p className="careerHelpHead">One - On - One</p>
              <p>
                Enjoy one-on-one mentorship sessions tailored to your specific
                career goals and learning needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersBody;
